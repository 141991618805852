@import url("https://fonts.googleapis.com/css?family=Fira+Sans");
@import './node_modules/bootstrap/scss/mixins.scss';
@import './node_modules/bootstrap/scss/functions.scss';

// bootstrap variables overrides
@import 'bs_variables';
@import "./node_modules/bootstrap/scss/root";
@import "./node_modules/bootstrap/scss/reboot";
@import "./node_modules/bootstrap/scss/type";
@import "./node_modules/bootstrap/scss/images";
@import "./node_modules/bootstrap/scss/grid";
@import "./node_modules/bootstrap/scss/forms";
@import "./node_modules/bootstrap/scss/buttons";
@import "./node_modules/bootstrap/scss/transitions";
@import "./node_modules/bootstrap/scss/dropdown";
@import "./node_modules/bootstrap/scss/custom-forms";
@import "./node_modules/bootstrap/scss/nav";
@import "./node_modules/bootstrap/scss/navbar";
@import "./node_modules/bootstrap/scss/utilities";
@import "./node_modules/bootstrap/scss/tooltip";

@import "./icons";

$navy: #1f2b2e;
$navyTransparent: rgba(31, 43, 46, 0.8);
$blue: #0e4368;
$water: #3bade1;
$green: #78b636;
$lightblue: #74A4D8;

html, body {
  font-family: 'Fira Sans', sans-serif;
}

.app {
  h1 {
    font-size: 2.25rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1.0rem;
  }

  p, td {
    font-size: 0.8rem;
  }
  
  .text-lightblue {
    color: $lightblue;
  }
  .line-height-1 {
    line-height: 1rem;
  }

  .navbar {
    box-shadow: 0px 2px 8px #888;
    z-index: 999;
    color: $blue;
    background: white;

    a {
      color: $blue;
    }

    .dropdown-menu {
      a:active {
        background-color: $green;
      }
    }

    #navbar-nav {
      padding-right: 40px;
    }
  }

  .section {
    height: 100vh;
    // height: calc(100vh - 68.5px);
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;

    &:first-child {
      box-shadow: 0px 2px 8px #888;
    }
  }

  .section.map {
    // margin-top: 68.5px;

    .map-columns {
      display: flex;
      flex-direction: row;
      overflow: auto;

      .sidebar {
        // width: 300px;
        overflow-x: hidden;
        overflow-y: auto;
        color: white;
        // background: $navy;
        background: transparent; //$navyTransparent;
        // max-height: calc(100vh - 320px);
        min-height: calc(100vh - 320px);
        max-height: 100vh;

        .ant-collapse {
          // background: $navy;
          background: transparent;
          border: 0;
          overflow: hidden;

          .ant-collapse-item {
            border-bottom: 0;// 1px solid $water;

            .ant-collapse-header {
              // color: white;
              @extend .text-muted;
              padding: 12px 12px 12px 24px;

              .ant-collapse-arrow {
                left: 0;
                top: 44%;
              }

              .ant-collapse-extra {
                margin-top: -2px;

                a {
                  color: $water;
                  &:hover {
                    color: white;
                  }
                }
              }
            }

            .ant-collapse-content {
              // background: $navy;
              background: transparent;
              color: inherit;
              border-top: 1px solid $water;

              .ant-collapse-content-box {
                color: inherit;
                padding: 10px;
                
                .ant-checkbox-wrapper {
                  margin-left: 0px;
                  color: inherit;
      
                  span {
                    // font-size: 0.7rem;
                  }
                }
              }
            }
          }

          .ant-collapse-item.leaf {
            .ant-collapse-content-box {
              padding: 10px 0;
            }
          }
        }

        h1 {
          font-size: 1.5rem;
          cursor: pointer;
          margin-top: 2.0rem;
          
          &:first-of-type {
            margin-top: 0;
          }

          &:hover {
            text-decoration: underline;
          }
        }

        .category {
          margin-bottom: 1.0rem;

          .ant-checkbox-wrapper {
            margin-left: 0;
            color: white;

            span {
              font-size: 0.7rem;
            }
          }
        }
      }

      .sidebar.show {
        @extend .px-3;
        @extend .py-2;
        width: auto;
        overflow: visible;
        direction: ltr;
      }

      .map-and-context {
        flex: 1;
        // height: calc(100vh - 68.5px); // header height = 68.5px
        height: 100vh;
        .map-wrapper {
          flex: 1;
          height: 100vh;
          // height: calc(100vh - 68.5px); // header height = 68.5px
          cursor: crosshair;
        }
        
        .leaflet-container {
          height: 100%;
          width: 100%;
          cursor: default;
  
          .leaflet-touch,
          .leaflet-retina,
          .leaflet-fade-anim,
          .leaflet-grab,
          .leaflet-touch-drag,
          .leaflet-touch-zoom,
          .leaflet-pane,
          .leaflet-map-pane,
          .leaflet-tile-pane,
          .leaflet-layer,
          .leaflet-interactive,
          .leaflet-clickable {
            cursor: pointer;
          }

          .leaflet-google-mutant, 
          .leaflet-bottom, 
          .leaflet-top,
          .leaflet-right,
          .leaflet-control-attribution {
            z-index: 555;
          }
        }

        .context {
          &.show {
            @extend .text-muted;
            @extend .d-inline-flex;
            @extend .p-3;
            @extend .shadow;
            
            background: rgba(255, 255, 255, 0.9);
            height: auto;
            width: auto;
            // max-width: calc(100% - 90px);
            position: absolute;
            bottom: 50px;
            left: auto;
            right: 1rem;
            border-radius: 0.5rem;
            z-index: 557;
          }

          .context-charts {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            .chart {
              flex: 0 1;
            }
          }

          .loader-padding {
            padding-top: 20px;
          }

          .feature-context {
            font-size: 0.6rem
          }
        }
      }
    }
  }

  .section.about {
    height: auto;
    .info-nav {
      background-color: $navy;
      text-align: center;
      color: rgba(255, 255, 255, 0.5);
      &-item {
        display: inline-block;
        padding: 0.5rem 1.5rem;
        margin-top: 1rem;
        margin-bottom: 0;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        transition: all 250ms ease-in;
        &.active {
          border-bottom-color: $water;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
    .info {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      flex: 1 1 auto;
      background-color: $navy;
      color: white;
      padding: 40px 0px;
      min-height: 70vh;

      .chart {
        flex: 0 1;
      }

      @media (max-width: 990px) {
        overflow: auto;
        width: 100%;
        height: 100%;
        max-width: 100%;
        justify-content: start;
      }
    }

    .footer {
      color: $blue;
      background-color: white;
      width: 100%;
      height: 200px;
      padding: 30px 20px;

      p {
        font-size: 1.2rem;
      }
      
      .logo {
        max-height: 80px;
      }
      .logo-lg {
        max-height: 120px;
      }
      
      .version {
        position: absolute;
        bottom: -20px;
        right: 20px;
      }

      @media (max-width: 767px) {
        height: auto;
      }
    }
  }

  .logo {
    max-width: 150px;
  }

  .bold {
    font-weight: 800;
  }

  .center {
    text-align: center;
  }

  .block {
    display: block;
  }
}

.sidebar-wrapper {
  position: absolute;
  left: 0;
  // top: 70px;
  // height: calc(100vh - 70px);
  z-index: 556;
  // background: $navyTransparent;
  direction: rtl;
  overflow-y: auto;
}

.sidebar-item {
  @extend .rounded-lg;
  @extend .mb-2;
  @extend .text-muted;
  @extend .d-inline-flex;
  @extend .align-items-center;
  @extend .px-1;
  @extend .shadow;
  
  background-color: $white;
  min-height: 40px;
  min-width: 40px;
  width: auto;

  .nav-link {
    @extend .px-0;
  }
  .nav-item .nav-link {
    @extend .pr-2;
    color: inherit;
  }
  img {
    max-width: 100px;
  }
}

.btn-toggle {
  position: absolute;
  z-index: 556;
  top: calc(50% - 125px);
  left: 100%;
  // background: $navy;
  background: $navyTransparent;
  border: 0;
  padding: 0.75rem 0.35rem 0.75rem 0.25rem;
  border-radius: 0 0.25rem 0.25rem 0;
  outline: none;
  &:focus {
    outline: none;
  }
}

.map-legend {
  display: inline-block;
  width: 0.65rem;
  height: 0.18rem;
  vertical-align: middle;
  margin-left: 0.25rem;
}

.leaflet-left {
  right: 0.6rem;
  left: auto;
  top: 3rem;
}

@import 'antd-overrides';