$icon-basin: url("../images/icons/location-pointer0.svg");
$icon-management_zones: url("../images/icons/management_zones.svg");
$icon-breeding_zones: url("../images/icons/breeding_zones.svg");
$icon-semi_industrial_fisheries: url("../images/icons/semi_industrial_fisheries.svg");
$icon-gill_net_fisheries: url("../images/icons/gill_net_fisheries.svg");
$icon-subsistence_fisheries: url("../images/icons/subsistence_fisheries.svg");
$icon-sport_fisheries: url("../images/icons/sport_fisheries.svg");
$icon-commercial_aquaculture: url("../images/icons/commercial_aquaculture.svg");
$icon-rivers: url("../images/icons/rivers.svg");
$icon-small_scale_aquaculture: url("../images/icons/small_scale_aquaculture.svg");


.icon {
    display: inline-block;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    vertical-align: middle;
    
    &-base {
        width: 1.25rem;
        height: 1.25rem;
    }
    &-lg {
        width: 1.75rem;
        height: 1.75rem;
    }
    &-basin {
        background-image: $icon-basin;
    }
    &-management_zones {
        background-image: $icon-management_zones;
    }
    &-breeding_zones {
        background-image: $icon-breeding_zones;
    }
    &-semi_industrial_fisheries {
        background-image: $icon-semi_industrial_fisheries;
    }
    &-gill_net_fisheries {
        background-image: $icon-gill_net_fisheries;
    }
    &-subsistence_fisheries {
        background-image: $icon-subsistence_fisheries;
    }
    &-sport_fisheries {
        background-image: $icon-sport_fisheries;
    }
    &-commercial_aquaculture {
        background-image: $icon-commercial_aquaculture;
    }
    &-small_scale_aquaculture {
        background-image: $icon-small_scale_aquaculture;
    }
    &-rivers {
        background-image: $icon-rivers;
    }
}