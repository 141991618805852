.body {
  background: purple;
}

.ant-checkbox-inner {
  @extend .d-none;

  &:after {
    @extend .d-none;
  }
}

.ant-checkbox + span {
  @extend .px-0;
}

.ant-checkbox-wrapper {
  @extend .mx-auto;
}